<script>
import {
  typesUseDataType,
  typesUseDataTypeOptions,
  irrigationsDataType,
  irrigationsDataTypeOptions,
  fertilizedsDataType, 
  fertilizedsDataTypeOptions } from '@/utils/enums'
import gql from 'graphql-tag'
import cloneDeep from 'lodash/cloneDeep'
import Swal from 'sweetalert2';
import { mapGetters } from 'vuex'

export default {
  props: ['value', 'prefix'],

  apollo: {
    cultivars: {
      query: gql`
        query Cultivars {
          cultivars(order_by: {name: asc}) {
            id
            name
            others
          }
        }
      `,

      fetchPolicy: 'cache-and-network',
    },
  },

  data () {
    return {
      dialog: false,
      loadingSave: false,
      valid: null,
      form: {paddock_cultivars: [{ cultivar_id: '40950fc1-9871-40fc-8fbc-e317d5239ee4', percentage: '100' }]},
      
      typesUseTypes: typesUseDataType,
      typesUseOptions: typesUseDataTypeOptions,
      irrigationsTypes: irrigationsDataType,
      irrigationsOptions: irrigationsDataTypeOptions,
      fertilizedsTypes: fertilizedsDataType,
      fertilizedsOptions: fertilizedsDataTypeOptions,

      validations: {
        name: [(v) => !!v || 'Nome é obrigatório'],
        code: [(v) => !!v || 'code é obrigatório'],
        fertilized: [(v) => !!v || 'Adubação é obrigatório'],
        type_use: [(v) => !!v || 'Tipo de uso é obrigatório'],
        cultivar_id: [(v) => !!v || 'Cultivar é obrigatório'],
        percentage: [(v) => v >= 0 || 'Participação mínima é 0'],
      },
    }
  },

  computed: {
    ...mapGetters('auth', ['user']),
    cultivarsOptions() {
      if (this.cultivars) {
        return this.cultivars
          .map(({id, name, others}) => ({ text: `${name}${others ? ' / ' + others : ''}`, value: id}))
          .filter(item => item.value !== '40950fc1-9871-40fc-8fbc-e317d5239ee4')
      }
      return []
    },

    formCultivars() {
      if (this.form.paddock_cultivars) {
        return this.form.paddock_cultivars.filter(item => !item.remove)
      }
      return []
    }
  },

  watch: {
    form() {
      this.form.change = true
      this.$emit('input', this.form)
    }
  },

  created () {
    if (this.value) this.form = cloneDeep(this.value)
    this.$on('create', this.initCreate)
    this.$on('edit', this.initEdit)
  },

  methods: {
    initCreate () {
      this.form = {paddock_cultivars: [{ cultivar_id: '40950fc1-9871-40fc-8fbc-e317d5239ee4', percentage: '100' }]}
      this.dialog = true
      setTimeout(() => {
        this.$refs.form.resetValidation()
      }, 500)
    },

    initEdit (paddockParam) {
      const paddock = cloneDeep(paddockParam)
      if (!paddock.paddock_cultivars || paddock.paddock_cultivars.length === 0) {
        paddock.paddock_cultivars = [{ cultivar_id: '40950fc1-9871-40fc-8fbc-e317d5239ee4', percentage: '100' }]
      }
      this.form = paddock
      this.dialog = true
      setTimeout(() => {
        this.$refs.form.resetValidation()
      }, 500)
    },

    async save () {
      if (!this.$refs.form.validate()) return false;
      this.loadingSave = true
      try {
        if (!this.form.id) {
          // TODO insert paddock
        } else {
          await this.update()
          await this.updateCultivars()
          Swal.fire({
            icon: 'success',
            text: 'Registro alterado com sucesso!',
            position: 'top-end',
            toast: true,
            showConfirmButton: false,
            timer: 3000,
          });
        }
        this.dialog = false
        this.$emit('change', this.form)
      } catch (e){
        console.error(e)
      }
      this.loadingSave = false
    },

    async insert(paddock) {
      const res = await this.$apollo.mutate({
        mutation: gql`
          mutation ($paddock: paddocks_insert_input!) {
            insert_paddocks_one(object: $paddocks) {
              id
              name
              code
              module_id
            }
          }
        `,
        variables: {
          paddock
        },
      });
      return res.data.insert_paddocks.returning
    },

    getPaddockPreSave () {
      return {
        name: this.form.name,
        code: this.form.code,
        description: this.form.description,
        area: this.form.area === '' ? null : this.form.area,
        irrigation: this.form.irrigation,
        fertilized: this.form.fertilized,
        type_use: this.form.type_use,
        module_id: this.form.module_id,
        desactivated: this.form.desactivated,
        customer_id: this.form.customer_id,
      }
    },

    async update() {
      const paddock = this.getPaddockPreSave()
      await this.$apollo.mutate({
        mutation: gql`
          mutation ($id: uuid!, $paddock: paddocks_set_input!) {
            update_paddocks_by_pk(pk_columns: { id: $id }, _set: $paddock) {
              id
            }
          }
        `,
        variables: {
          id: this.form.id,
          paddock: paddock,
        },
      });
    },

    getCultivarsPreSave () {
      return this.formCultivars.map(item => ({
        cultivar_id: item.cultivar_id,
        percentage: item.percentage,
        paddock_id: this.form.id,
        customer_id: this.form.customer_id,
      }))
    },

    async updateCultivars() {
      const cultivars = this.getCultivarsPreSave()
      await this.$apollo.mutate({
        mutation: gql`
          mutation ($id: uuid!, $cultivars: [paddock_cultivars_insert_input!]!) {
            delete_paddock_cultivars( where:{ paddock_id: { _eq: $id } } ){
              affected_rows
            }
            insert_paddock_cultivars( objects: $cultivars ){
              affected_rows
            }
          }
        `,
        variables: {
          id: this.form.id,
          cultivars,
        },
      });
    },

    generateCode(val) {
      if (this.form.code) return
      const code = this.prefix + '_' + val.split(' ').reduce((acc, item) => {
        const numbers = item.replace(/\D/g, '');
        if (numbers) {
          acc += numbers
          return acc
        }
        acc += item[0].toUpperCase()
        return acc
      }, 'P')
      this.$set(this.form, 'code', code)
    },

    addCultivars () {
      if(!this.form.paddock_cultivars) this.$set(this.form, 'paddock_cultivars', [{}])
      else this.form.paddock_cultivars.push({})
    },

    delCultivar (index) {
      this.form.paddock_cultivars.splice(index, 1)
      this.handleChangeCultivar()
    },

    handleChangeCultivar () {
      let sum = 0
      this.formCultivars.forEach(item => {
        if (item.cultivar_id === '40950fc1-9871-40fc-8fbc-e317d5239ee4') return
        const percentage = parseFloat(item.percentage)
        if (percentage > 0) sum += percentage
      })
      this.formCultivars[0].percentage = 100 - sum
    }
  }
}
</script>

<template>
  <v-dialog v-model="dialog" max-width="1300">
    <v-form ref="form" v-model="valid" lazy-validation @submit.prevent="save">
      <v-card>
        <v-card-title class="text-h5">
          Piquete
        </v-card-title>

        <v-card-text class="pt-5">
          <v-row>
            <v-col cols="12" md="6">
              <v-row>
                <v-col cols="12" md="4">
                  <v-text-field
                    v-model="form.name"
                    label="Nome"
                    name="Nome"
                    :rules="validations.name"
                    :disabled="user.role ==='customer'"
                    outlined
                    persistent-placeholder
                    @change="generateCode($event)"
                  />
                </v-col>

                <v-col cols="12" md="4">
                  <v-text-field
                    v-model="form.code"
                    label="Código"
                    :rules="validations.code"
                    :disabled="user.role ==='customer'"
                    outlined
                    persistent-placeholder
                  />
                </v-col>

                <v-col cols="12" md="4">
                  <v-text-field
                    v-model="form.area"
                    label="Área"
                    suffix="ha"
                    type="number"
                    class="text-field-right text-field-suffix-light"
                    :disabled="user.role ==='customer'"
                    outlined
                    persistent-placeholder
                    @keydown.190.prevent
                    @keydown.110.prevent
                  />
                </v-col>

                <v-col cols="12" md="4">
                  <v-select
                    v-model="form.irrigation"
                    :rules="validations.irrigation"
                    :items="irrigationsOptions"
                    label="Irrigação"
                    :disabled="user.role ==='customer'"
                    outlined
                    persistent-placeholder
                  />
                </v-col>

                <v-col cols="12" md="4">
                  <v-select
                    v-model="form.fertilized"
                    :rules="validations.fertilized"
                    :items="fertilizedsOptions"
                    label="Adubação"
                    :disabled="user.role ==='customer'"
                    outlined
                    persistent-placeholder
                  />
                </v-col>

                <v-col cols="12" md="4">
                  <v-select
                    v-model="form.type_use"
                    :rules="validations.type_use"
                    :items="typesUseOptions"
                    label="Tipo de uso"
                    :disabled="user.role ==='customer'"
                    outlined
                    persistent-placeholder
                  />
                </v-col>
                <v-col cols="12" md="12">
                  <v-textarea
                    v-model="form.description"
                    label="Descrição"
                    rows="3"
                    :disabled="user.role ==='customer'"
                    outlined
                    persistent-placeholder
                  />
                </v-col>
                <v-col cols="12" md="4">
                  <v-checkbox
                      v-model="form.desactivated"
                      :disabled="user.role ==='customer'"
                      label="Desativar Piquete ?"
                      hide-details
                      class="shrink mr-2 mt-0"
                    />
                </v-col>
              </v-row>
            </v-col>

            <v-col cols="12" md="6">
              <v-card outlined>
                <v-card-title>Pasto: espécies e cultivares</v-card-title>
                <v-card-text>
                  <v-row>
                    <v-col cols="12" md="7">
                      <v-text-field
                        value="Calva (sem pasto)"
                        label="Cultivar"
                        :rules="validations.cultivar_id"
                        :disabled="user.role ==='customer'"
                        readonly
                        dense
                        outlined
                        persistent-placeholder
                      />
                    </v-col>
                    <v-col cols="12" md="5" class="pr-15">
                      <v-text-field
                        :value="formCultivars[0] ? formCultivars[0].percentage : 0"
                        :rules="validations.percentage"
                        :disabled="user.role ==='customer'"
                        label="Participação"
                        suffix="%"
                        type="number"
                        class="text-field-right text-field-suffix-light"
                        readonly
                        dense
                        outlined
                        persistent-placeholder
                        @keydown.190.prevent
                        @keydown.110.prevent
                      />
                    </v-col>
                  </v-row>
                  <v-row v-for="(item, index) in formCultivars" :key="'form_cultivars_' + index">
                    <template v-if="index > 0">
                      <v-col cols="12" md="7">
                        <v-autocomplete
                          v-model="item.cultivar_id"
                          :items="cultivarsOptions"
                          :rules="validations.cultivar_id"
                          label="Cultivar"
                          dense
                          outlined
                          persistent-placeholder
                          @change="handleChangeCultivar(item)"
                        />
                      </v-col>
                      <v-col cols="12" md="5" class="d-inline-flex">
                        <v-text-field
                          v-model="item.percentage"
                          :rules="validations.percentage"
                          label="Participação"
                          suffix="%"
                          type="number"
                          class="text-field-right text-field-suffix-light"
                          dense
                          outlined
                          persistent-placeholder
                          @change="handleChangeCultivar(item)"
                          @keydown.190.prevent
                          @keydown.110.prevent
                        />
                        <v-btn text icon class="ml-3" color="error" @click="delCultivar(index)">
                          <v-icon small>mdi-delete</v-icon>
                        </v-btn>
                      </v-col>
                    </template>
                  </v-row>
                  <div class="mt-4">
                    <v-btn v-if="user.role !=='customer'" color="primary" :disabled="form.paddock_cultivars && form.paddock_cultivars.length >= 5" @click="addCultivars">
                      <v-icon small>mdi-plus</v-icon>
                      Adicionar
                    </v-btn>
                  </div>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
        </v-card-text>

        <v-card-actions>
          <v-spacer />
          <v-btn color="primary" text @click="dialog = false">Cancelar</v-btn>
          <v-btn v-if="user.role !=='customer'" color="success" :disabled="loadingSave" :loading="loadingSave" @click="save">Salvar</v-btn>
        </v-card-actions>
      </v-card>
    </v-form>
  </v-dialog>
</template>
