<script>
// import StatesJSON from '@/utils/data/states'
import gql from 'graphql-tag';
import Swal from 'sweetalert2';

export default {

  props: ['id','farmId', 'farmName', 'customerId', 'customerName'],

  data() {
    return {
      loading: false,
      customers: [],
      modulesList: [],
      form: {},
    };
  },

  async created() {
    this.loading = true
      this.form = await this.fetch();
      delete this.form.__typename;
      if(this.form && this.form.modules) {
        this.modulesList = this.form.modules
      }
    this.loading = false
  },

  methods: {
    async save() {
      this.loading = true;
      try {
          await this.update();
          Swal.fire({
            icon: 'success',
            text: 'Registro alterado com sucesso!',
            position: 'top-end',
            toast: true,
            showConfirmButton: false,
            timer: 3000,
          });
      } catch (e) {
        console.error(e)
      }
      this.loading = false;
    },

    async update() {
      for(let index in this.modulesList) {
      delete this.modulesList[index].__typename
      await this.$apollo.mutate({
        mutation: gql`
          mutation ($id: uuid!, $module: modules_set_input!) {
            update_modules_by_pk(pk_columns: { id: $id }, _set: $module) {
              desired_use
              factor_adjust_fdn
              factor_adjust_pb
              factor_adjust_em
              desactivated
            }
          }
        `,
        variables: {
          id: this.modulesList[index].id,
          module: this.modulesList[index]
        },
      });
      }
    },

    async fetch() {
      try {
        const result = await this.$apollo.query({
          query: gql`
            query farms($id: uuid!) {
              farm: farms_by_pk(id: $id) {
                name
                customer_id
                modules(order_by: {code: asc}) {
                  id
                  name
                  desired_use
                  farm_id
                  factor_adjust_fdn
                  factor_adjust_pb
                  factor_adjust_em
                  desactivated
                }
              }
            }
          `,
          variables: {
            id: this.farmId,
          },
          fetchPolicy: 'no-cache',
        });
        return result.data.farm;
      } catch (e) {
        console.error(e);
      }
    },

    call() {
      this.save()
    }
  },
};
</script>

<template>
  <div>
    <h1 class="mb-5">Fazenda {{ form.name }}</h1>
    <v-card v-if="!loading">
      <v-card-text>
        <div v-for="(item,index) in modulesList" :key="index">
          <h2 class="mb-3">{{ item.name }}</h2>
          <v-row>
            <v-col>
              <v-text-field v-model="item.desired_use"
                label="Aproveitamento desejado %"
                suffix="%"
                type="number"
                class="text-field-right text-field-suffix-light"
                outlined
                persistent-placeholder
              />
            </v-col>
            <v-col>
              <v-text-field v-model="item.factor_adjust_fdn"
              label="FDN"
              type="number"
              class="text-field-right text-field-suffix-light"
              suffix="%"
              outlined
              persistent-placeholder
              @input="item.factor_adjust_fdn = $event !== '' ? $event : null"
            />
            </v-col>
            <v-col>
              <v-text-field v-model="item.factor_adjust_pb"
              label="PB"
              type="number"
              class="text-field-right text-field-suffix-light"
              suffix="%"
              outlined
              persistent-placeholder
              @input="item.factor_adjust_pb = $event !== '' ? $event : null"
            />
            </v-col>
            <v-col>
              <v-text-field v-model="item.factor_adjust_em"
              label="EM"
              type="number"
              class="text-field-right text-field-suffix-light"
              suffix="%"
              outlined
              persistent-placeholder
              @input="item.factor_adjust_em = $event !== '' ? $event : null"
            />
            </v-col>
          </v-row>
        </div>
        <v-row class="d-flex pa-5">
          <v-spacer />
          <v-btn color="success" @click="call">Salvar</v-btn>
        </v-row>
      </v-card-text>
    </v-card>
  </div>
</template>
