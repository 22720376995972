<script>
import {
  typesUseDataType,
  typesUseDataTypeOptions,
  irrigationsDataType,
  irrigationsDataTypeOptions,
  fertilizedsDataType, 
  fertilizedsDataTypeOptions } from '@/utils/enums'
import gql from 'graphql-tag'
import cloneDeep from 'lodash/cloneDeep'
import Swal from 'sweetalert2';
import { mapGetters } from 'vuex'

import RegisterPaddock from '../paddock/Register.vue'

export default {
  components: {
    RegisterPaddock
  },

  props: ['module'],

  data () {
    return {
      dialog: false,
      valid: null,
      currentPaddock: null,
      loadingSave: false,
      color: '#------',
      form: {},
      swatches: [
        ['#FF0000', '#AA0000', '#550000'],
        ['#FFFF00', '#AAAA00', '#555500'],
        ['#00FF00', '#00AA00', '#005500'],
        ['#00FFFF', '#00AAAA', '#005555'],
        ['#0000FF', '#0000AA', '#000055'],
      ],

      typesUseTypes: typesUseDataType,
      typesUseOptions: typesUseDataTypeOptions,
      irrigationsTypes: irrigationsDataType,
      irrigationsOptions: irrigationsDataTypeOptions,
      fertilizedsTypes: fertilizedsDataType,
      fertilizedsOptions: fertilizedsDataTypeOptions,

      validations: {
        name: [(v) => !!v || 'Nome é obrigatório'],
        code: [(v) => !!v || 'code é obrigatório'],
        fertilized: [(v) => !!v || 'Adubação é obrigatório'],
        type_use: [(v) => !!v || 'Tipo de uso é obrigatório'],
      },
    }
  },

  computed: {
    ...mapGetters('auth', ['isManager'])
  },

  created () {
    if (this.module) this.form = cloneDeep(this.module)
    this.$on('create', this.initCreate)
    this.$on('edit', this.initEdit)
  },

  methods: {
    initCreate () {
      this.form = {}
      this.dialog = true
      setTimeout(() => {
        this.$refs.form.resetValidation()
      }, 500)
    },

    initEdit (module) {
      this.form = cloneDeep(module)
      this.dialog = true
      setTimeout(() => {
        this.$refs.form.resetValidation()
      }, 500)
    },

    generateCode(val) {
      if (this.form.code) return
      const code = val.split(' ').reduce((acc, item) => {
        const numbers = item.replace(/\D/g, '');
        if (numbers) {
          acc += numbers
          return acc
        }
        acc += item[0].toUpperCase()
        return acc
      }, 'M')
      this.$set(this.form, 'code', code)
    },


    async save() {
      if (!this.$refs.form.validate()) return false;
      this.loadingSave = true;
      if (this.color !== '#------') {
        this.form.color = this.color
      }
      try {
        const module = cloneDeep(this.form)
        module.desired_use = parseFloat(module.desired_use)
        await this.updateModule(module)
        this.dialog = false
        this.$emit('change', module)
        Swal.fire({
          icon: 'success',
          text: 'Registro alterado com sucesso!',
          position: 'top-end',
          toast: true,
          showConfirmButton: false,
          timer: 3000,
        });
      } catch (e) {
        console.error(e)
      }
      this.loadingSave = false;
    },


    async updateModule(module) {
      const modulePreSave = {
        name: module.name,
        code: module.code,
        desired_use: module.desired_use,
        irrigation: module.irrigation,
        fertilized: module.fertilized,
        type_use: module.type_use,
        description: module.description,
        desactivated: module.desactivated,
        deleted_at: module.deleted_at,
        factor_adjust_pb: module.factor_adjust_pb,
        factor_adjust_fdn: module.factor_adjust_fdn,
        factor_adjust_em: module.factor_adjust_em,
      }
      if(module.color) {
        modulePreSave.module_color = module.color
      }
      await this.$apollo.mutate({
        mutation: gql`
          mutation ($id: uuid!, $module: modules_set_input!) {
            update_modules_by_pk(pk_columns: { id: $id }, _set: $module) {
              id
            }
          }
        `,
        variables: {
          id: module.id,
          module: modulePreSave,
        },
      });
    },

    cancel(){
      this.dialog = false
      this.$emit('cancel')
    },
  }
}
</script>

<template>
  <v-dialog v-model="dialog" max-width="600">
    <v-form ref="form" v-model="valid" lazy-validation @submit.prevent="save">
      <v-card>
        <v-card-title class="text-h5">
          Módulo
        </v-card-title>

        <v-card-text class="pt-5">
          <v-row>
            <v-col cols="12" md="12">
              <v-text-field
                v-model="form.name"
                label="Nome"
                name="Nome"
                :disabled="!isManager"
                :rules="validations.name"
                outlined
                persistent-placeholder
                @change="generateCode($event)"
              />
            </v-col>

            <v-col cols="12" md="4">
              <v-text-field
                v-model="form.code"
                label="Código"
                :disabled="!isManager"
                :rules="validations.code"
                outlined
                persistent-placeholder
              />
            </v-col>

            <v-col cols="12" md="4">
              <v-text-field
                v-model="form.desired_use"
                label="Aproveitamento desejado"
                suffix="%"
                type="number"
                class="text-field-right text-field-suffix-light"
                :disabled="!isManager"
                outlined
                persistent-placeholder
                @keydown.190.prevent
                @keydown.110.prevent
              />
            </v-col>

            <v-col cols="12" md="4">
              <v-select
                v-model="form.irrigation"
                :rules="validations.irrigation"
                :items="irrigationsOptions"
                :disabled="!isManager"
                label="Irrigação"
                outlined
                persistent-placeholder
              />
            </v-col>

            <v-col cols="12" md="4">
              <v-select
                v-model="form.fertilized"
                :rules="validations.fertilized"
                :items="fertilizedsOptions"
                :disabled="!isManager"
                label="Adubação"
                outlined
                persistent-placeholder
              />
            </v-col>

            <v-col cols="12" md="4">
              <v-select
                v-model="form.type_use"
                :rules="validations.type_use"
                :items="typesUseOptions"
                :disabled="!isManager"
                label="Tipo de uso"
                outlined
                persistent-placeholder
              />
            </v-col>
            <v-col cols="12" md="4">
              <v-text-field
                v-model="form.factor_adjust_pb"
                :rules="validations.factor_adjust_pb"
                :disabled="!isManager"
                label="PB"
                type="number"
                class="text-field-right text-field-suffix-light"
                outlined
                persistent-placeholder
                @input="form.factor_adjust_pb = $event !== '' ? $event : null"
              />
            </v-col>
            <v-col cols="12" md="4">
              <v-text-field
                v-model="form.factor_adjust_fdn"
                :rules="validations.factor_adjust_fdn"
                :disabled="!isManager"
                label="FDN"
                type="number"
                class="text-field-right text-field-suffix-light"
                outlined
                persistent-placeholder
                @input="form.factor_adjust_fdn = $event !== '' ? $event : null"
              />
            </v-col>
            <v-col cols="12" md="4">
              <v-text-field
                v-model="form.factor_adjust_em"
                :rules="validations.factor_adjust_em"
                :disabled="!isManager"
                label="EM"
                type="number"
                class="text-field-right text-field-suffix-light"
                outlined
                persistent-placeholder
                @input="form.factor_adjust_em = $event !== '' ? $event : null"
              />
            </v-col>
            <v-col cols="12" md="4">
              <v-checkbox
                  v-model="form.desactivated"
                  :disabled="!isManager"
                  label="Desativar Modulo?"
                  hide-details
                  class="shrink mr-2 mt-0"
                />
            </v-col>
            <v-expand-transition v-if="isManager">
                  <v-expansion-panels>
                    <v-expansion-panel>
                      <v-expansion-panel-header>
                        <span bold>Mapa: Seleção da cor do módulo</span>
                      </v-expansion-panel-header>
                      <v-expansion-panel-content>
                        <v-col cols="12" md="12">
                        <v-color-picker
                          v-model="color"
                          dot-size="25"
                          mode="rgba"
                          :swatches="swatches"
                          show-swatches
                          hide-canvas
                          hide-sliders
                          hide-inputs
                          swatches-max-height="200"
                        ></v-color-picker>
                      </v-col>
                      </v-expansion-panel-content>
                    </v-expansion-panel>
                  </v-expansion-panels>
            </v-expand-transition>

            <v-col cols="12" md="12">
              <v-textarea
                v-model="form.description"
                :disabled="!isManager"
                label="Descrição"
                rows="3"
                outlined
                persistent-placeholder
              />
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn v-if="isManager" color="primary" plain class="mr-2" @click="cancel">Cancelar</v-btn>
          <v-btn v-if="isManager" color="success" :loading="loadingSave" :disabled="loadingSave" @click="save"> Salvar </v-btn>
        </v-card-actions>
      </v-card>
    </v-form>
  </v-dialog>
</template>

