<script>
import Confirmation from '@/components/Confirmation.vue'
import gql from 'graphql-tag'
import cloneDeep from 'lodash/cloneDeep'
import isEqual from 'lodash/isEqual'
import Swal from 'sweetalert2';
import { mapGetters } from 'vuex'

import ListPaddock from '../paddock/List.vue'
import RegisterModule from './Register.vue'
import RegisterModuleTemp from './RegisterTemp.vue'


export default {
  components:{
    Confirmation,
    RegisterModule,
    RegisterModuleTemp,
    ListPaddock
  },

  props: ['value', 'farmId', 'farmName', 'customerId', 'customerName', 'desactivated'],

  data() {
    return {
      loadingCreate: false,
      innerValue: [],
      innerValueDesactivateds: [],
      expanded: [],
      expandedDesactivated: [],
      headers: [
        { text: 'Nome', value: 'name', align: 'start' },
        { text: 'Código', value: 'code', align: 'center' },
        { text: 'Aproveitamento desejado', value: 'desired_use', align: 'center' },
        { text: 'Área', value: 'area', align: 'center' },
        { text: 'Quantidade de piquetes', value: 'paddocks.length', align: 'center' },
        { text: 'Ações', value: '_actions', sortable: false, align: 'end' },
      ]
    }
  },

  computed: {
    ...mapGetters('auth', ['isManager', 'isConsultant'])
  },

  watch: {
    value (val) {
      if(!isEqual(this.innerValue, val)) {
        this.innerValue = cloneDeep(val)
        this.innerValueDesactivateds = this.innerValue.filter(item => item.desactivated)
        this.innerValue = this.innerValue.filter(item => !item.desactivated)
      }
    },

    innerValue (val) {
      this.$emit('input', val)
    }
  },

  created() {
    if(!isEqual(this.innerValue, this.value)) {
      this.innerValue = cloneDeep(this.value)
      this.innerValueDesactivateds = this.innerValue.filter(item => item.desactivated)
      this.innerValue = this.innerValue.filter(item => !item.desactivated)
    }
  },

  methods: {
    async insertModule(module) {
      const res = await this.$apollo.mutate({
        mutation: gql`
          mutation ($module: modules_insert_input!) {
            insert_modules_one(object: $module) {
              id
            }
          }
        `,
        variables: {
          module
        },
      });
      return res.data.insert_modules_one
    },

    async addModule() {
      this.loadingCreate = true
      try {
        if (!this.innerValue) this.innerValue = []
        let number = this.innerValue.length + 1
        number = number > 9 ? number : '0' + number
        const module = {
          name: 'Módulo ' + number,
          code: 'M' + number,
          farm_id: this.farmId,
          customer_id: this.customerId,
        }
        const { id } = await this.insertModule(module)
        module.id = id
        module.paddocks = []
        this.innerValue.push(module)
        Swal.fire({
          icon: 'success',
          text: 'Registro criado com sucesso!',
          position: 'top-end',
          toast: true,
          showConfirmButton: false,
          timer: 3000,
        });
      } catch (e) {
        console.error(e)
      }
      this.loadingCreate = false
    },

    async addModuleTemporary() {
      this.loadingCreate = true
      try {
        if (!this.innerValue) this.innerValue = []
        let number = this.innerValue.length + 1
        number = number > 9 ? number : '0' + number
        const module = {
          name: 'Módulo ' + number,
          code: 'T' + number,
          farm_id: this.farmId,
          customer_id: this.customerId,
          temporary: true
        }
        const { id } = await this.insertModule(module)
        module.id = id
        module.paddocks = []
        this.innerValue.push(module)
        Swal.fire({
          icon: 'success',
          text: 'Registro criado com sucesso!',
          position: 'top-end',
          toast: true,
          showConfirmButton: false,
          timer: 3000,
        });
      } catch (e) {
        console.error(e)
      }
      this.loadingCreate = false
    },

    checkUser() {
      if (!this.isManager) {
        if (this.desactivated) {
          return true
        } else {
          return false
        }
      } else {
        return false
      }
    },

    handleChangeModule(module) {
      this.innerValue = this.innerValue.map(item => {
        if (item.id === module.id) return module
        return item
      })
    },

    handleRefreshPage() {
      this.$emit('refresh')
    },

    handleChangePaddocks(module, paddocks) {
      module.paddocks = paddocks
      this.handleChangeModule(module)
    },

    getRowClass(item) {
      if(item.temporary){
        return 'green lighten-5'
      } 
    },

    editModule(module) {
      if (module.temporary) {
        this.$refs.registerTemp.$emit('edit', {module: module, modules: this.innerValue})
      } else {
        this.$refs.register.$emit('edit', module)
      }
    },

    async removeModule(id) {
      try{
        await this.$apollo.mutate({
          mutation: gql`
            mutation ($id: uuid!, $module: modules_set_input!) {
              update_modules_by_pk(pk_columns: { id: $id }, _set: $module) {
                id
              }
            }
          `,
          variables: {
            id,
            module: {
              deleted_at: new Date()
            },
          },
        });
        this.innerValue = this.innerValue.filter(item => item.id !== id)
        Swal.fire({
          icon: 'success',
          text: 'Registro alterado com sucesso!',
          position: 'top-end',
          toast: true,
          showConfirmButton: false,
          timer: 3000,
        });
      } catch (e) {
        console.error(e)
      }
    }
  }
}
</script>

<template>
  <div>
    <v-alert
      border="left"
      colored-border
      color="red darken-4"
      elevation="1"
    >
      <div class="body-1 d-flex justify-content-center align-items-center">
        <v-icon class="title pr-2">mdi-barn</v-icon> {{ farmName | defaultEmpty }}
      </div>
      <div v-if="isManager || isConsultant" class="body-1 d-flex justify-content-center align-items-center mt-2">
        <v-icon class="title pr-2">mdi-account</v-icon> {{ customerName | defaultEmpty }}
      </div>
    </v-alert>
    <v-data-table
      :headers="headers"
      :items="innerValue"
      :single-expand="true"
      :expanded.sync="expanded"
      disable-pagination
      hide-default-footer
      :item-class="getRowClass"
    >
      <template v-slot:top>
        <v-form>
          <v-row>
            <v-col cols="12" sm="12" :md="$vuetify.breakpoint.md? '6' : '8'" xl="6">
              <v-toolbar-title>Módulos</v-toolbar-title>
            </v-col>
            <v-col cols="12" sm="12" :md="$vuetify.breakpoint.md? '4' : '2'" xl="3" class="text-right">
              <v-btn :loading="loadingCreate" :disabled="checkUser()" color="primary" outlined @click="addModuleTemporary">
                <v-icon left>
                  mdi-plus
                </v-icon>
                Módulo Temporário
              </v-btn>
            </v-col>
            <v-col v-if="isManager" cols="12" sm="12" md="2" :class="$vuetify.breakpoint.xs? 'text-left' : $vuetify.breakpoint.md? 'text-right': $vuetify.breakpoint.lg? 'text-right': 'text-left'">
              <v-btn :loading="loadingCreate" :disabled="loadingCreate" color="primary" outlined @click="addModule">
                <v-icon left>
                  mdi-plus
                </v-icon>
                Módulo
              </v-btn>
            </v-col>
          </v-row>
        </v-form>
      </template>
      <template v-slot:[`item.desired_use`]="{ item }"> {{ item.desired_use | percent(2) }} </template>
      <template v-slot:[`item.area`]="{ item }"> {{ item.paddocks_aggregate? item.paddocks_aggregate.aggregate.sum.area : 0 | number:decimals(2) }} </template>
      <template v-slot:[`item.paddocks.length`]="{ item }">
        <v-btn text icon color="primary" @click="expanded[0] === item ? expanded = [] : expanded = [item]">
          {{ item.paddocks.length }}
        </v-btn>
      </template>
      <template v-slot:[`item._actions`]="{ item }">
        <v-btn v-if="!isManager && !item.temporary" text icon color="primary" @click="editModule(item)">
          <v-icon small>mdi-eye</v-icon>
        </v-btn>
        <v-btn v-if="isManager || item.temporary" text icon color="primary" @click="editModule(item)">
          <v-icon small>mdi-pencil</v-icon>
        </v-btn>
        <confirmation v-if="isManager" @success="() => removeModule(item.id)">
          <v-btn text icon color="error">
            <v-icon small>mdi-delete</v-icon>
          </v-btn>
        </confirmation>
      </template>
      <template v-slot:expanded-item="{ headers, item }">
        <td :colspan="headers.length" class="grey lighten-4 pa-3 py-10">
          <list-paddock
            :paddocks="item.paddocks"
            :module="item"
            :farm-id="farmId"
            :customer-id="customerId"
            @change="($event) => handleChangePaddocks(item, $event)"
          />
        </td>
      </template>
    </v-data-table>
    
    <v-col cols="12" sm="12" :md="$vuetify.breakpoint.md? '6' : '8'" xl="6">
      <v-toolbar-title style="color: #000000DE;">Módulos Inativos</v-toolbar-title>
    </v-col>
    <v-data-table 
      :headers="headers"
      :items="innerValueDesactivateds"
      :single-expand="true"
      :expanded.sync="expandedDesactivated"
      disable-pagination
      hide-default-footer
      :item-class="getRowClass">
      <template v-slot:[`item.desired_use`]="{ item }"> {{ item.desired_use | percent(2) }} </template>
      <template v-slot:[`item.paddocks.length`]="{ item }">
        <v-btn text icon color="primary" 
        @click="expandedDesactivated[0] === item ? expandedDesactivated = [] : expandedDesactivated = [item]">
          {{ item.paddocks.length }}
        </v-btn>
      </template>
      <template v-slot:[`item._actions`]="{ item }">
        <v-btn text icon color="primary" @click="editModule(item)">
          <v-icon small>mdi-pencil</v-icon>
        </v-btn>
        <confirmation @success="() => removeModule(item.id)">
          <v-btn text icon color="error">
            <v-icon small>mdi-delete</v-icon>
          </v-btn>
        </confirmation>
      </template>
      <template v-slot:expanded-item="{ headers, item }">
        <td :colspan="headers.length" class="grey lighten-4 pa-3 py-10">
          <list-paddock
            :paddocks="item.paddocks"
            :module="item"
            :farm-id="farmId"
            :customer-id="customerId"
            @change="($event) => handleChangePaddocks(item, $event)"
          />
        </td>
      </template>
    </v-data-table>
    <register-module ref="register" @change="handleChangeModule"/>
    <register-module-temp ref="registerTemp" :desactivated="desactivated" @refresh='handleRefreshPage' @change="handleChangeModule"/>
  </div>
</template>

<style >
.v-data-table__expanded__row {
  background-color: #E3F2FD!important;
}
</style>
