<script>
import Confirmation from '@/components/Confirmation.vue'
import gql from 'graphql-tag'
import cloneDeep from 'lodash/cloneDeep'
import isEqual from 'lodash/isEqual'
import Swal from 'sweetalert2';
import { mapGetters } from 'vuex'

import RegisterPaddock from './Register.vue'

export default {
  components:{
    Confirmation,
    RegisterPaddock
  },

  props: ['paddocks', 'module', 'farmId', 'customerId'],
  

  data() {
    return {
      loadingCreate: false,
      innerValue: [],
      headers: [
        { text: 'Nome', value: 'name', align: 'start' },
        { text: 'Código', value: 'code', align: 'center' },
        { text: 'Área', value: 'area', align: 'center' },
        { text: 'Módulo Referência', value: 'ref_module.name', align: 'center' },
        { text: 'Ações', value: '_actions', sortable: false, align: 'end' },
      ]
    }
  },

  computed: {
    ...mapGetters('auth', ['user'])
  },

  watch: {
    paddocks (val) {
      if(!isEqual(this.innerValue, val)) {
        this.innerValue = cloneDeep(val)
      }
    },
  },

  created() {
    if(!isEqual(this.innerValue, this.paddocks)) {
      this.innerValue = cloneDeep(this.paddocks)
    }
  },

  methods: {
    async insertPaddock(paddock) {
      const res = await this.$apollo.mutate({
        mutation: gql`
          mutation ($paddock: paddocks_insert_input!) {
            insert_paddocks_one(object: $paddock) {
              id
            }
          }
        `,
        variables: {
          paddock
        },
      });
      return res.data.insert_paddocks_one
    },

    async updatePaddock(paddock) {
      const paddockPreSave = {
        name: paddock.name,
        code: paddock.code,
        description: paddock.description,
        deleted_at: paddock.deleted_at
      }
      await this.$apollo.mutate({
        mutation: gql`
          mutation ($id: uuid!, $paddock: paddocks_set_input!) {
            update_paddocks_by_pk(pk_columns: { id: $id }, _set: $paddock) {
              id
            }
          }
        `,
        variables: {
          id: paddock.id,
          paddock: paddockPreSave,
        },
      });
    },

    async addPaddock() {
      this.loadingCreate = true
      try {
        if (!this.innerValue) this.innerValue = []
        const lastPaddock = this.innerValue[this.innerValue.length - 1]
        let number
        console.log(lastPaddock)
        if (this.innerValue.length === 0) number = this.innerValue.length + 1
        else number = Number(lastPaddock.code.substring(lastPaddock.code.indexOf('_P') + 2)) + 1
        number = number > 9 ? number : '0' + number
        const paddock = {
          name: 'Piquete ' + number,
          code: this.module.code + '_P' + number,
          module_id: this.module.id,
          customer_id: this.customerId
        }
        if (lastPaddock) {
          paddock.area = lastPaddock.area
          paddock.irrigation = lastPaddock.irrigation
          paddock.fertilized = lastPaddock.fertilized
          paddock.type_use = lastPaddock.type_use
          if (lastPaddock.paddock_cultivars) {
            paddock.paddock_cultivars = {
              data: lastPaddock.paddock_cultivars
                .map(item => ({
                  customer_id: paddock.customer_id,
                  cultivar_id: item.cultivar_id,
                  percentage: item.percentage,
                }))
            }
          }
        }
        else {
          paddock.irrigation = this.module.irrigation
          paddock.fertilized = this.module.fertilized
          paddock.type_use = this.module.type_use
        }
        const { id } = await this.insertPaddock(paddock)
        paddock.id = id
        if (paddock.paddock_cultivars) {
          paddock.paddock_cultivars = paddock.paddock_cultivars.data
        }
        this.innerValue.push(paddock)
        this.$emit('change', this.innerValue)
        Swal.fire({
          icon: 'success',
          text: 'Registro criado com sucesso!',
          position: 'top-end',
          toast: true,
          showConfirmButton: false,
          timer: 3000,
        });
      } catch (e) {
        console.error(e)
      }
      this.loadingCreate = false
    },

    async removePaddock(id) {
      try{
        await this.$apollo.mutate({
          mutation: gql`
            mutation ($id: uuid!, $paddock: paddocks_set_input!) {
              update_paddocks_by_pk(pk_columns: { id: $id }, _set: $paddock) {
                id
              }
            }
          `,
          variables: {
            id,
            paddock: {
              deleted_at: new Date()
            },
          },
        });
        this.innerValue = this.innerValue.filter(item => item.id !== id)
        this.$emit('change', this.innerValue)
        Swal.fire({
          icon: 'success',
          text: 'Registro alterado com sucesso!',
          position: 'top-end',
          toast: true,
          showConfirmButton: false,
          timer: 3000,
        });
      } catch (e) {
        console.error(e)
      }
    },

    editPaddock(paddock) {
      this.$refs.register.$emit('edit', paddock)
    },

    getRowClass(item) {
      if( item.ref_module_id && item.ref_module_id !== item.module_id && !this.module.temporary){
        return 'green lighten-5'
      } 
    },

    handleChangePaddock(paddock) {
      this.innerValue = this.innerValue.map(item => {
        if (item.id === paddock.id) return paddock
        return item
      })
      this.$emit('change', this.innerValue)
    }
  }
}
</script>

<template>
  <div>
    <v-data-table
      :headers="headers"
      :items="innerValue"
      disable-pagination
      hide-default-footer
      :item-class="getRowClass"
    >
      <template v-slot:top>
        <v-toolbar flat>
          <v-toolbar-title>Piquetes</v-toolbar-title>
          <v-spacer />
          <v-btn v-show="!module.temporary" :loading="loadingCreate" :disabled="loadingCreate" color="primary" outlined @click="addPaddock">
            <v-icon left>
              mdi-plus
            </v-icon>
            Piquete
          </v-btn>
        </v-toolbar>
      </template>
      <template v-slot:[`item.ref_module.name`]="{ item }">
        {{ item.ref_module? item.module.name : '' | defaultEmpty }}
      </template>
      <template v-slot:[`item.area`]="{ item }">
        {{ item.area | number:decimals(3, ' ha') | defaultEmpty }}
      </template>
      <template v-slot:[`item._actions`]="{ item }">
        <v-btn v-if="user.role !== 'customer'" text icon color="primary" @click="() => editPaddock(item)">
          <v-icon small>mdi-pencil</v-icon>
        </v-btn>
        <v-btn v-if="user.role === 'customer'" text icon color="primary" @click="() => editPaddock(item)">
          <v-icon small>mdi-eye</v-icon>
        </v-btn>
        <confirmation @success="() => removePaddock(item.id)">
          <v-btn v-if="user.role !== 'customer'" text icon color="error">
            <v-icon small>mdi-delete</v-icon>
          </v-btn>
        </confirmation>
      </template>
    </v-data-table>
    <register-paddock ref="register" :prefix="module.code" @change="handleChangePaddock" />
  </div>
</template>

<style >
.v-data-table__expanded__row {
  background-color: #BBDEFB!important;
}
</style>
