<script>
import {
  typesUseDataType,
  typesUseDataTypeOptions,
  irrigationsDataType,
  irrigationsDataTypeOptions,
  fertilizedsDataType, 
  fertilizedsDataTypeOptions } from '@/utils/enums'
import dayjs from 'dayjs';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import gql from 'graphql-tag'
import cloneDeep from 'lodash/cloneDeep'
import Swal from 'sweetalert2';
import { mapGetters } from 'vuex'


import RegisterPaddock from '../paddock/Register.vue'

dayjs.extend(utc)
dayjs.extend(timezone)

export default {
  components: {
    RegisterPaddock
  },

  props: ['module','desactivated'],

  data () {
    return {
      dialog: false,
      valid: null,
      currentPaddock: null,
      loadingSave: false,
      form: {},
      modules: [],
      checkboxes: [],

      typesUseTypes: typesUseDataType,
      typesUseOptions: typesUseDataTypeOptions,
      irrigationsTypes: irrigationsDataType,
      irrigationsOptions: irrigationsDataTypeOptions,
      fertilizedsTypes: fertilizedsDataType,
      fertilizedsOptions: fertilizedsDataTypeOptions,

      validations: {
        name: [(v) => !!v || 'Nome é obrigatório'],
        code: [(v) => !!v || 'code é obrigatório'],
        fertilized: [(v) => !!v || 'Adubação é obrigatório'],
        type_use: [(v) => !!v || 'Tipo de uso é obrigatório'],
        start_date: [(v) => !!v || 'Data de início é obrigatório'],
      },
    }
  },

  computed: {
    ...mapGetters('auth', ['isManager'])
  },

  created () {
    if (this.module) { 
      this.form = cloneDeep(this.module)
      if (this.form.start_date) {
        this.form.start_date = dayjs(this.form.start_date).format('YYYY-MM-DD')
      }
    }
    this.$on('create', this.initCreate)
    this.$on('edit', this.initEdit)
  },
  
  methods: {
    initCreate () {
      this.form = {}
      this.dialog = true
      setTimeout(() => {
        this.$refs.form.resetValidation()
      }, 500)
    },

    initEdit (module) {
      this.form = cloneDeep(module.module)
      if (this.form.start_date) {
        this.form.start_date = dayjs(this.form.start_date).utc().format('YYYY-MM-DD')
      }
      this.modules = cloneDeep(module.modules)
      let limit = this.modules.length
      for (let index = 0; index < limit; index++) {
        let paddocks = this.modules[index].paddocks
        if (paddocks.length > 0) {
          this.checkboxes[index] = []
          if (this.modules[index].code === this.form.code && this.modules[index].id === this.form.id) {
            for (let i = 0; i < paddocks.length; i++ ) {
                this.checkboxes[index][i] = true
            }
          }
        }
      }
      this.dialog = true
      setTimeout(() => {
        this.$refs.form.resetValidation()
      }, 500)
    },

    generateCode(val) {
      if (this.form.code) return
      const code = val.split(' ').reduce((acc, item) => {
        const numbers = item.replace(/\D/g, '');
        if (numbers) {
          acc += numbers
          return acc
        }
        acc += item[0].toUpperCase()
        return acc
      }, 'M')
      this.$set(this.form, 'code', code)
    },

    mostOcurrence(arr) {
      return arr.sort((a,b) =>
          arr.filter(v => v===a).length
        - arr.filter(v => v===b).length
      ).pop();
    },

    async save() {
      if (!this.$refs.form.validate()) return false;
      this.loadingSave = true;
      try {
        let desiredUse = []
        let irrigation = []
        let fertilized = []
        let typeUse = []
        const module = cloneDeep(this.form)
        for (let i = 0; i < this.checkboxes.length; i++) {
          if (this.checkboxes[i]) {
            let countTrue = 0
            if (this.modules[i].paddocks.length === this.checkboxes[i].length && this.modules[i].id !== this.form.id) {
              for (let j = 0; j < this.checkboxes[i].length; j++) {
                if (this.checkboxes[i][j] === true) {
                  countTrue += 1
                } else if (this.modules[i].paddocks[j].module_id === this.form.id) {
                  countTrue += 1
                }
              }
              if (countTrue === this.checkboxes[i].length) {
                let batchesWithAnimal = await this.checkModuleBatches(this.modules[i].id)
                if (batchesWithAnimal) {
                  Swal.fire({
                    icon: 'error',
                    text: this.modules[i].name + ' contem lotes com animais, não é possível pegar todos os piquetes',
                    position: 'top-end',
                    toast: true,
                    showConfirmButton: false,
                    timer: 5000,
                  });
                  throw this.modules[i].name + ' contem lotes com animais, não é possível pegar todos os piquetes'
                }
              }
            }
          }
        }
        for (let i = 0; i < this.checkboxes.length; i++ ) {
          if (this.checkboxes[i]) {
            for (let j = 0; j < this.checkboxes[i].length; j++) {
              if (this.checkboxes[i][j] === true ) {
                desiredUse.push(this.modules[i].desired_use)
                irrigation.push(this.modules[i].paddocks[j].irrigation)
                fertilized.push(this.modules[i].paddocks[j].fertilized)
                typeUse.push(this.modules[i].paddocks[j].type_use)
                if (!(this.modules[i].paddocks[j].module_id === this.form.id)) {
                  let paddock = {
                    id: this.modules[i].paddocks[j].id,
                    module_id: this.form.id,
                    ref_module_id: this.modules[i].paddocks[j].module_id
                  }
                  this.updatePaddock(paddock)
                  this.updateTemporaryModulePaddocks(true,this.modules[i].paddocks[j].id)
                }         
              } else if (this.checkboxes[i][j] === false) {
                if (this.modules[i].paddocks[j].module_id === this.form.id) {
                  let paddock = {
                    id: this.modules[i].paddocks[j].id,
                    module_id: this.modules[i].paddocks[j].ref_module_id,
                    ref_module_id: null,
                  }
                  this.updatePaddock(paddock) 
                  this.updateTemporaryModulePaddocks(false, this.modules[i].paddocks[j].id)
                }
              }
            }
            let check = await this.checkModuleBatches(this.modules[i].id)
            if (check) {
              let batchesWithAnimal = await this.getModuleBatches(this.modules[i].id)
              try {
                for(let index = 0; index < batchesWithAnimal.length; index++) {
                  let batch = batchesWithAnimal[index]
                  let transaction = {
                    number_of_heads: batch.number_of_heads,
                    average_weight: batch.average_weight,
                    genetic_pattern: batch.genetic_pattern,
                    category_animal: batch.category_animal,
                    category_complementary: batch.category_complementary,
                    customer_id : batch.customer.id,
                    batch_id : batch.id,
                    day_occupation : batch.day_occupation,
                    supplement_mineral_consumption : batch.supplement_mineral_consumption ,
                    supplement_mineral_pb : batch.supplement_mineral_pb,
                    supplement_mineral_ndt : batch.supplement_mineral_ndt,
                    supplement_feed_consumption : batch.supplement_feed_consumption,
                    supplement_feed_pb : batch.supplement_feed_pb,
                    supplement_feed_ndt : batch.supplement_feed_ndt,
                    supplement_feed_fdn : batch.supplement_feed_fdn,
                    supplement_vol_consumption : batch.supplement_vol_consumption,
                    supplement_vol_ms : batch.supplement_vol_ms,
                    supplement_vol_pb : batch.supplement_vol_pb,
                    supplement_vol_em : batch.supplement_vol_em ,
                    supplement_vol_fdn : batch.supplement_vol_fdn,
                    event_date: this.form.start_date + 'T' + '00:00',
                    type: 'REDUCAO_DE_MODULO'
                  }
                  console.log('transaction :', transaction)
                  await this.$apollo.mutate({
                    mutation: gql`
                      mutation ($transaction: batchTransactionInput!) {
                        batch_transactions_add(data: $transaction) {
                          success
                        }
                      }
                    `,
                    variables: {
                      transaction: transaction
                    },
                  }); 
                }
              } catch (e) {
                Swal.fire({
                  icon: 'error',
                  text: e.message,
                  showConfirmButton: false,
                  timer: 2500,
                });
              }
            }
          }
        }
        if (desiredUse.length > 0) {
          module.desired_use = 0
          for (let i = 0; i < desiredUse.length; i++) {
            module.desired_use += desiredUse[i]
          }
          module.desired_use = module.desired_use / desiredUse.length
          let response = this.mostOcurrence(irrigation)
          module.irrigation = response
          response = this.mostOcurrence(fertilized)
          module.fertilized = response
          response = this.mostOcurrence(typeUse)
          module.type_use = response
        }
        if (this.form.desactivated) {
          for (let i = 0; i  < this.modules.length; i++) {
            if (this.modules[i].id === this.form.id) {
              for (let j = 0; j < this.modules[i].paddocks.length; j++) {
                if (this.modules[i].paddocks[j].module_id === this.form.id) {
                  let paddock = {
                    id: this.modules[i].paddocks[j].id,
                    module_id: this.modules[i].paddocks[j].ref_module_id,
                    ref_module_id: this.modules[i].paddocks[j].module_id
                  }
                  this.updatePaddock(paddock)
                }
              }
            }
          }
        } else {
          const paddocks = await this.$apollo.query({
            query: gql`
              query paddocks($module_id: uuid! ) {
                paddocks(
                  where: {  
                    ref_module_id: {_eq: $module_id}
                  }  order_by: { created_at: asc }
                ) {
                    id
                    ref_module_id
                    module_id
                  }
              }
            `,
            variables: {
              module_id: this.form.id,
            },
            fetchPolicy: 'no-cache',
            });
            if (paddocks && paddocks.data && paddocks.data.paddocks && paddocks.data.paddocks.length > 0) {
              for (let i = 0 ; i < paddocks.data.paddocks.length; i++) {
                let paddock = {
                  id: paddocks.data.paddocks[i].id,
                  module_id: paddocks.data.paddocks[i].ref_module_id,
                  ref_module_id: paddocks.data.paddocks[i].module_id
                }
                this.updatePaddock(paddock)
              }
            }
        }

        module.desired_use = parseFloat(module.desired_use)
        await this.updateModule(module)
        this.dialog = false
        this.$emit('change', module)
        this.$emit('refresh')
        Swal.fire({
          icon: 'success',
          text: 'Registro alterado com sucesso!',
          position: 'top-end',
          toast: true,
          showConfirmButton: false,
          timer: 3000,
        });
      } catch (e) {
        console.error(e)
      }
      this.loadingSave = false;
    },


    async updateModule(module) {
      const modulePreSave = {
        name: module.name,
        code: module.code,
        desired_use: module.desired_use,
        irrigation: module.irrigation,
        fertilized: module.fertilized,
        type_use: module.type_use,
        description: module.description,
        desactivated: module.desactivated,
        deleted_at: module.deleted_at,
        factor_adjust_pb: module.factor_adjust_pb,
        factor_adjust_fdn: module.factor_adjust_fdn,
        factor_adjust_em: module.factor_adjust_em,
        start_date: module.start_date
      }
      await this.$apollo.mutate({
        mutation: gql`
          mutation ($id: uuid!, $module: modules_set_input!) {
            update_modules_by_pk(pk_columns: { id: $id }, _set: $module) {
              id
            }
          }
        `,
        variables: {
          id: module.id,
          module: modulePreSave,
        },
      });
    },

    async updatePaddock(paddock) {
      await this.$apollo.mutate({
        mutation: gql`
          mutation ($id: uuid!, $paddock: paddocks_set_input!) {
            update_paddocks_by_pk(pk_columns: { id: $id }, _set: $paddock) {
              id
            }
          }
        `,
        variables: {
          id: paddock.id,
          paddock: paddock,
        },
      });
    },

    async updatePaddockSatelliteData(paddock) {
      let time = dayjs(this.form.created_at).subtract(60,'day')
      time = time.startOf('day')
      let list = await this.$apollo.query({
        query: gql`
          query satellites_data($id: uuid!, $time: timestamptz!) {
            satellites_data(where: {paddock_id: {_eq: $id}, created_at: {_gt: $time}}) {
              id
            }
          }
        `,
        variables: {
          id: paddock.id,
          time: time,
        },
        fetchPolicy: 'no-cache',
      });
      let sateliteData 
      if (list && list.data && list.data.satellites_data) {
        sateliteData = list.data.satellites_data
      }
      if (sateliteData) {
        for (let index = 0; index < sateliteData.length; index++) {
          await this.$apollo.mutate({
            mutation: gql`
              mutation ($id: uuid!, $data: satellites_data_set_input!) {
                update_satellites_data_by_pk(pk_columns: { id: $id }, _set: $data) {
                  id
                }
              }
            `,
            variables: {
              id: sateliteData[index].id,
              data: {
                module_id: paddock.module_id
              },
            },
          });
        }
      }
    },

    async updateTemporaryModulePaddocks(check, id) {
      if(check) {
        let data = {
          paddock_id: id,
          module_id: this.form.id,
          start_date: dayjs(this.form.start_date),
        }
        await this.$apollo.mutate({
        mutation: gql`
          mutation ($data: temporary_module_paddocks_insert_input!) {
            insert_temporary_module_paddocks_one(object: $data) {
              id
            }
          }
        `,
        variables: {
          data
        },
      });
      } else {
        let result = await this.$apollo.query({
          query: gql`
            query temporary_module_paddocks($paddock_id: uuid! ) {
              temporary_module_paddocks(
                where: {  
                  paddock_id: {_eq: $paddock_id}
                }  order_by: { start_date: desc },
                   limit: 1
              ) {
                  id
                }
            }
          `,
          variables: {
            paddock_id: id,
          },
          fetchPolicy: 'no-cache',
        });
        let update_id = result.data.temporary_module_paddocks[0].id
        await this.$apollo.mutate({
            mutation: gql`
              mutation ($id: uuid!, $data: temporary_module_paddocks_set_input!) {
                update_temporary_module_paddocks_by_pk(pk_columns: { id: $id }, _set: $data) {
                  id
                }
              }
            `,
            variables: {
              id: update_id,
              data: {
                end_date: dayjs()
              },
            },
        });
      }
    },

    checkDisabledCheckbox(paddock,module) {
      
      if (paddock.ref_module_id && paddock.ref_module_id !== paddock.module_id && paddock.ref_module_id !== this.form.id 
      && module.id !== this.form.id) {
        return true
      } else {
        return false
      }
    },

    checkUser() {
      if (!this.isManager) {
        if (this.desactivated) {
          return true
        } else {
          return false
        }
      } else {
        return false
      }
    },

    async checkModuleBatches(id) {
      const batches = await this.$apollo.query({
        query: gql`
          query batches($module_id: uuid! ) {
            batches(
              where: { 
                _and: [
                  { number_of_heads: {_gt: "0" } }, 
                  { module_id: {_eq: $module_id} }
                ]
              }  order_by: { created_at: asc }
            ) {
                id
              }
          }
        `,
        variables: {
          module_id: id,
        },
        fetchPolicy: 'no-cache',
        });
      if (batches && batches.data && batches.data.batches && batches.data.batches.length > 0) {
        return true
      } else {
        return false 
      }
    },

    async getModuleBatches(id) {
      const batches = await this.$apollo.query({
        query: gql`
          query batches($module_id: uuid! ) {
            batches(
              where: { 
                _and: [
                  { number_of_heads: {_gt: "0" } }, 
                  { module_id: {_eq: $module_id} }
                ]
              }  order_by: { created_at: asc }
            ) {
              number_of_heads
              average_weight
              genetic_pattern
              category_animal
              category_complementary
              customer {
                id
              }
              id
              day_occupation
              supplement_mineral_consumption
              supplement_mineral_pb
              supplement_mineral_ndt
              supplement_feed_consumption
              supplement_feed_pb
              supplement_feed_ndt
              supplement_feed_fdn
              supplement_vol_consumption
              supplement_vol_ms
              supplement_vol_pb
              supplement_vol_em
              supplement_vol_fdn
              }
          }
        `,
        variables: {
          module_id: id,
        },
        fetchPolicy: 'no-cache',
        });
      if (batches && batches.data && batches.data.batches && batches.data.batches.length > 0) {
        return batches.data.batches
      } else {
        return false
      }
    },


    displayPaddockName(paddock) {
      return paddock.name + (paddock.ref_module? '('+ paddock.ref_module.name + ')' : '')
    },

    cancel(){
      this.dialog = false
      this.$emit('cancel')
    },
  }
}
</script>

<template>
  <v-dialog v-model="dialog" max-width="600">
    <v-form ref="form" v-model="valid" lazy-validation @submit.prevent="save">
      <v-card>
        <v-card-title class="text-h5">
          Módulo
        </v-card-title>

        <v-card-text class="pt-5">
          <v-row>
            <v-col cols="12" md="12">
              <v-text-field
                v-model="form.name"
                label="Nome"
                name="Nome"
                :rules="validations.name"
                outlined
                persistent-placeholder
                @change="generateCode($event)"
              />
            </v-col>

            <v-col cols="12" md="4">
              <v-text-field
                v-model="form.code"
                label="Código"
                :rules="validations.code"
                outlined
                persistent-placeholder
              />
            </v-col>
            <v-col cols="12" md="4">
              <v-text-field
                v-model="form.desired_use"
                label="Aproveitamento desejado"
                suffix="%"
                type="number"
                class="text-field-right text-field-suffix-light"
                outlined
                persistent-placeholder
                @keydown.190.prevent
                @keydown.110.prevent
              />
            </v-col>

            <v-col cols="12" md="4">
              <v-select
                v-model="form.irrigation"
                :rules="validations.irrigation"
                :items="irrigationsOptions"
                label="Irrigação"
                outlined
                persistent-placeholder
              />
            </v-col>

            <v-col cols="12" md="4">
              <v-select
                v-model="form.fertilized"
                :rules="validations.fertilized"
                :items="fertilizedsOptions"
                label="Adubação"
                outlined
                persistent-placeholder
              />
            </v-col>

            <v-col cols="12" md="4">
              <v-select
                v-model="form.type_use"
                :rules="validations.type_use"
                :items="typesUseOptions"
                label="Tipo de uso"
                outlined
                persistent-placeholder
              />
            </v-col>
            <v-col cols="12" md="4">
              <v-text-field
                v-model="form.factor_adjust_pb"
                :rules="validations.factor_adjust_pb"
                label="PB"
                type="number"
                class="text-field-right text-field-suffix-light"
                outlined
                persistent-placeholder
                @input="form.factor_adjust_pb = $event !== '' ? $event : null"
              />
            </v-col>
            <v-col cols="12" md="4">
              <v-text-field
                v-model="form.factor_adjust_fdn"
                :rules="validations.factor_adjust_fdn"
                label="FDN"
                type="number"
                class="text-field-right text-field-suffix-light"
                outlined
                persistent-placeholder
                @input="form.factor_adjust_fdn = $event !== '' ? $event : null"
              />
            </v-col>
            <v-col cols="12" md="4">
              <v-text-field
                v-model="form.factor_adjust_em"
                :rules="validations.factor_adjust_em"
                label="EM"
                type="number"
                class="text-field-right text-field-suffix-light"
                outlined
                persistent-placeholder
                @input="form.factor_adjust_em = $event !== '' ? $event : null"
              />
            </v-col>
            <v-col cols="12" md="4">
              <v-text-field
                v-model="form.start_date"
                :rules="validations.start_date"
                label="data de início"
                type="date"
                class="text-field-right text-field-suffix-light"
                outlined
                persistent-placeholder
              />
            </v-col>
            <v-col cols="12" md="4">
              <v-checkbox
                  v-model="form.desactivated"
                  label="Desativar Modulo?"
                  hide-details
                  class="shrink mr-2 mt-0"
                />
            </v-col>

            <v-col cols="12" md="12">
              <v-textarea
                v-model="form.description"
                label="Descrição"
                rows="3"
                outlined
                persistent-placeholder
              />
            </v-col>
            <v-col cols="12" md="12">
              <div class="text-h5"> Selecione os Piquetes: </div>
              <v-expansion-panels>
                <v-expansion-panel
                  v-for="(item,i) in modules"
                  :key="i"
                >
                  <v-expansion-panel-header v-if="item.paddocks.length > 0">
                    {{ item.name }}
                  </v-expansion-panel-header>
                  <v-expansion-panel-content v-if="item.paddocks.length > 0">
                    <div v-for="(paddock, j) in item.paddocks" :key="j">
                      <v-checkbox
                      v-model="checkboxes[i][j]"
                      :disabled="checkDisabledCheckbox(paddock,item)"
                      :label="displayPaddockName(paddock)"
                      >
                      </v-checkbox>
                    </div>
                  </v-expansion-panel-content>
                </v-expansion-panel>
              </v-expansion-panels>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn color="primary" plain class="mr-2" @click="cancel">Cancelar</v-btn>
          <v-btn color="success" :loading="loadingSave" :disabled="checkUser()" @click="save"> Salvar </v-btn>
        </v-card-actions>
      </v-card>
    </v-form>
  </v-dialog>
</template>
